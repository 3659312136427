<div class="shadow p-3 bg-white m-1">

    <div class="row">
        <div class="column center">
            <img [src]="imageChange()" class="coverImg">
            <app-cropper [photoType]="'resourcesEdit'" (imageSaved)="refresh($event)" [id]="id">
            </app-cropper>
            <form [formGroup]=" resourcesForm">
                <div class="form-group">
                    <input formControlName="title" type="text" id="title" class="form-control" placeholder="Title" />
                </div>
                <div class="form-group">
                    <input formControlName="subtitle" type="text" id="subtitle" class="form-control"
                        placeholder="Resource Description" />
                </div>
                <div class="form-group">
                    <input formControlName="link" type="text" id="link" class="form-control"
                        placeholder="Link/email to resource" />
                </div>
                <div class="form-group">
                    <input formControlName="description" type="text" id="description" class="form-control"
                        placeholder="Photo Description" />
                </div>

                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="delete()">DELETE</button>
                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="update()">SAVE</button>
            </form>

        </div>
    </div>
</div>