<div class="main_container">
  <h1 class="container_title">{{ verified ? 'APPROVED USERS' :'SOME USERS NEED YOUR APPROVAL'}}</h1>
  <button mat-raised-button color="primary" class="container_button" (click)="changeVerifiedValue()">Show {{ verified ?
    'unverified' : 'verified' }} users</button>
</div>

<div class="mat-elevation-z8 main" *ngIf="!hideTable">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef id="name">Name</th>
      <td mat-cell *matCellDef="let m"> {{m.name | titlecase}} </td>
    </ng-container>


    <ng-container matColumnDef="Lastname">
      <th mat-header-cell *matHeaderCellDef id="lastname">Lastname</th>
      <td mat-cell *matCellDef="let m"> {{m.lastName | titlecase}} </td>
    </ng-container>


    <ng-container matColumnDef="Profile">
      <th mat-header-cell *matHeaderCellDef id="profile"> Profile</th>
      <td mat-cell *matCellDef="let m">
        <a target="_blank"
          href="https://bipocdoceditors.com/profile/{{m._id}}">https://bipocdoceditors.com/profile/{{m._id}}</a>
      </td>
    </ng-container>

        <ng-container matColumnDef="documentaryExperience">
          <th mat-header-cell *matHeaderCellDef id="documentaryExperience">Documentary Experience</th>
          <td mat-cell *matCellDef="let m">
            <mat-chip-list *ngFor="let DE of m.documentaryExperience">
              <mat-chip color="primary" selected>{{DE}}</mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

    <ng-container matColumnDef="Action">
      <th mat-header-cell *matHeaderCellDef id="action"> Action</th>
      <td mat-cell *matCellDef="let m">
        <mat-form-field appearance="outline">
          <mat-select (selectionChange)="selectAction($event)" [id]="m._id">
            <mat-option value="accept">Accept</mat-option>
            <mat-option value="deny">Deny</mat-option>
          </mat-select>
        </mat-form-field>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  </table>
  <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"
    [length]="totalSize" [pageIndex]="currentPage" (page)="pageEvent = handlePage($event)" showFirstLastButtons>
  </mat-paginator>
</div>

<br />

<div style="text-align: right;">
  <!-- <button mat-raised-button color="primary" class="container_button">Verify users</button> -->

  <button mat-raised-button color="primary" class="container_button">
    <a target="_blank" style="color: white;" href="https://admin.bipocdoceditors.com/actions">Go To Admin Action Page</a>
  </button>

</div>


<div class="mat-elevation-z8 main d-flex justify-content-center" *ngIf="hideTable">
  <img src=" ../../../assets/images/Search.svg" class="search-img">
</div>

<!-- NEED TO WORK ON THIS! -->
<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">ARE YOU SURE?</h4>
        <button type="button" class="close" aria-label="Close" (click)="cancelDenyUser()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="body_text">Are you sure you want to DENY? This will permanently delete this profile.</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="cancelDenyUser()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="denyUser()">Deny user</button>
    </div>
</ng-template>
