<div class="mat-elevation-z8 main">
    <table mat-table [dataSource]="dataSource">

        <!-- Checkbox Column -->
        <ng-container matColumnDef="Selected">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <section class="example-section">
                    <mat-checkbox class="example-margin" color="primary"></mat-checkbox>
                </section>
            </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef>Name</th>
            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
        </ng-container>

        <!-- Lastname Column -->
        <ng-container matColumnDef="Lastname">
            <th mat-header-cell *matHeaderCellDef>Lastname</th>
            <td mat-cell *matCellDef="let element"> {{element.Lastname}} </td>
        </ng-container>

        <!-- Profile Column -->
        <ng-container matColumnDef="Profile">
            <th mat-header-cell *matHeaderCellDef>Profile</th>
            <td mat-cell *matCellDef="let element">
                <a href="#">{{element.Profile}}</a>
            </td>
        </ng-container>

        <!-- Action Column -->
        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef>Action</th>
            <td mat-cell *matCellDef="let element">
                <mat-form-field appearance="outline">
                    <mat-select [(value)]="selected">
                        <mat-option value="option1">Accept</mat-option>
                        <mat-option value="option2">Deny</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                <a href="#">{{element.Email}}</a>
            </td>
        </ng-container>

        <!-- Message Column -->
        <ng-container matColumnDef="Message">
            <th mat-header-cell *matHeaderCellDef>Message</th>
            <td mat-cell *matCellDef="let element">
                <a (click)="open(content)">View message</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
    </table>


</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">NEW MESSAGE</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="messageForm" (ngSubmit)="submit()">

            <div class="form-group">
                <input formControlName="name" type="text" id="name" class="form-control" placeholder="Name" />
            </div>
            <div class="form-group">
                <input formControlName="email" type="email" id="inputEmail" class="form-control" placeholder="Email" />
            </div>
            <div class="form-group">
                <textarea class="form-control msg" id="message" rows="3" formControlName="message"
                    placeholder="Message"></textarea>
            </div>


            <div class="modal-header">
                <h4 class="container_title" id="modal-basic-title">YOUR ANSWER</h4>
            </div>
            <div class="form-group">
                <textarea class="form-control msg" id="answer" rows="3" formControlName="answer"
                    placeholder="Your answer"></textarea>
            </div>
            <div class="button_container">
                <button mat-flat-button color="primary" class="container_button" type="submit">SEND</button>
            </div>
        </form>
    </div>
</ng-template>