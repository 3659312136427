import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserService } from 'src/app/services/user.service';
import { filtersForm } from 'src/app/utils/filters';


@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit {

  emailForm: FormGroup;
  form: FormGroup;
  closeResult = '';
  verified:boolean=true;

  //Table
  dataSource: any = [];
  users: any = [];
  displayedColumns: any[] = ['Selected', 'Name', 'Lastname', 'Profile', 'Email'];
  pageEvent: PageEvent;
  currentPage = 1;
  pageSize = 5;
  totalSize = 0;
  selectedUsers: any = [];
  ids: any = [];
  currentUse: any;
  check: boolean = false;
  hideTable: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private modalService: NgbModal, private fb: FormBuilder, private userService: UserService) {
    this.emailForm = this.fb.group({
      subject: ['', [Validators.required]],
      message: ['', [Validators.required]],
    })
    this.form = filtersForm;
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  //Modal
  open(content) {
    this.getEmail();
    this.modalService.open(content).result.then((result) => {

    }, (reason) => {

    });
  }



  submit() {
    const idList = this.ids.join(",");
    this.userService.emailUser(idList, this.emailForm.value)
    this.modalService.dismissAll();
    this.emailForm.reset();
  }

  //Users
  fetchUsers(keyword: string = ''): void {
    this.hideTable = false;
    this.userService.adminSearch(keyword, {}, this.verified).subscribe(d => {
      this.dataSource = d;
      this.users = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.users.length;
      this.iterator();
    }, e => {
      this.hideTable = true;
    });
  }

  getEmail() {
    this.selectedUsers.forEach(id => {
      this.users.findIndex(e => {
        if (e.user_id === id) {
          this.ids.push(e.user_id);
        }
      })
    })
  }

  //Paginator
  private iterator(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.users.slice(start, end);
    this.dataSource = part;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();

    return e;
  }

  //Checkbox
  onChange(value: boolean, id: string): void {
    if (value) {
      this.selectedUsers.push(id);
    } else {
      const i = this.selectedUsers.findIndex(l => l === id);
      this.selectedUsers.splice(i, 1);
    }
  }

  checkAll(e) {
    if (e.checked) {
      this.check = true;
      this.users.forEach(element => {
        this.onChange(true, element.user_id);
      });
    } else {
      this.check = false;
      this.selectedUsers = [];
    }
  }

  //Searchbar
  searchByKeyword(event: string): void {
    this.fetchUsers(event);
  }

  searchByFilter(event: any): void {
    this.hideTable = false;
    this.userService.adminSearch('', event, true).subscribe(d => {
      this.dataSource = d;
      this.users = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.users.length;
      this.currentPage = 1;
      this.iterator();
    }, e => {
      this.hideTable = true;
    })
  }

  //Verified users
  changeVerifiedValue(){
    this.verified = !this.verified;
    this.fetchUsers();
  }
}
