<div class="main-container">
    <div class="row">
        <div class="column left">
            <div class="d-flex align-items-center justify-content-center">
                <img class="column_img" src="assets/images/login.svg" alt="login">
            </div>
        </div>
        <div class="column right">
            <div>
                <h1 class="column_title">WELCOME</h1>
                <p class="column_subtitle">Log into your workplace</p>
            </div>
            <div class="d-flex align-items-center justify-content-center">
                <div class="d-flex align-items-center justify-content-center shadow column_card">
                    <form [formGroup]="signinForm" (ngSubmit)="submit()">

                        <div class="form-group">
                            <input formControlName="email" type="email" id="inputEmail" class="form-control"
                                placeholder="Email" />
                        </div>

                        <div class="form-group">
                            <input type="password" class="form-control" id="password" formControlName="password"
                                placeholder="Password">
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary">SIGN
                                IN</button>
                        </div>

                        <div class="d-flex align-items-center justify-content-center mb-5">
                            <a class="column__bottomtitle" (click)="open(content)">Forgot
                                password</a>
                        </div>


                    </form>

                </div>
            </div>



        </div>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">RECOVER PASSWORD</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="forgotPassword" (ngSubmit)="recoverPassword()">
            <div class="form-group">
                <input formControlName="email" type="email" id="inputEmail" class="form-control"
                    placeholder="Type in your email account" />
            </div>
            <div class="button_container">
                <button mat-flat-button color="primary" class="container_button" type="submit">SEND</button>
            </div>
        </form>
    </div>
</ng-template>