<input #imgInput type="file" (change)="fileChangeEvent($event)" hidden="true" accept="image/*" />
<button mat-flat-button color="primary" (click)="imgInput.click()" class="purple_button" type="button">UPLOAD
    PHOTO</button>




<ng-template #modalImg let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">ADJUST YOUR IMAGE</h4>
    </div>
    <div class="modal-body">
        <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
            [aspectRatio]="selectedRatio" format="png" (imageCropped)="imageCropped($event)"
            (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
        <img [src]="croppedImage" class="croppedimg" />
        <div class="d-flex justify-content-center align-items-center">
            <button mat-flat-button color="primary" (click)="uploadImage()" class="purple_button"
                type="button">CROP</button>
        </div>

    </div>
</ng-template>