import { Component, Input, AfterViewInit, ViewChild, OnInit, } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { ContactService, Res } from 'src/app/services/contact.service';





@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  constructor(private modalService: NgbModal, private fb: FormBuilder, private contactService: ContactService) {
    this.messageForm = this.fb.group({
      name: [''],
      email: [''],
      message: [''],
      answer: [''],
    })
  }

  @Input() typeColumn: string;
  messageForm: FormGroup;


  dataSource: any;
  selected = '';
  columnsToDisplay: any = [];
  requestsColumns: any = ['Name', 'Lastname', 'Profile', 'Action'];
  useremailColumns: any = ['Selected', 'Name', 'Lastname', 'Profile', 'Email'];
  inboxColumns: any = ['Name', 'Lastname', 'Email', 'Message'];

  $response: Observable<Res>;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<RequestsInterface>(REQUESTS_DATA);
    if (this.typeColumn === "requestsColumns") {
      this.dataSource = new MatTableDataSource<RequestsInterface>(REQUESTS_DATA);
      this.requestsColumns.forEach(i => this.columnsToDisplay.push(i))
      this.dataSource.paginator = this.paginator;
    } else if (this.typeColumn === "useremailColumns") {
      this.dataSource = new MatTableDataSource<UserEmailInterface>(USEREMAIL_DATA);
      this.useremailColumns.forEach(i => this.columnsToDisplay.push(i))
      this.dataSource.paginator = this.paginator;
    } else {
      this.dataSource = new MatTableDataSource<inboxInterface>(INBOX_DATA);
      this.inboxColumns.forEach(i => this.columnsToDisplay.push(i))
      this.dataSource.paginator = this.paginator;
    }
  }

  open(content) {
    this.modalService.open(content);
  }

  submit() {
    console.log("Message sent")
    this.modalService.dismissAll();
  }

}

export interface RequestsInterface {
  Name: string;
  Lastname: string;
  Profile: string;
  Action: string;
}

export interface UserEmailInterface {
  Selected: boolean;
  Name: string;
  Lastname: string;
  Profile: string;
  Email: string;
}

export interface inboxInterface {
  Name: string;
  Lastname: string;
  Email: string;
  Message: string;
}

const REQUESTS_DATA: RequestsInterface[] = [
  { Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Action: 'Hola' },
  { Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Action: 'Hola' },
  { Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Action: 'Hola' },
];

const USEREMAIL_DATA: UserEmailInterface[] = [
  { Selected: false, Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Email: 'barush@gmail.com' },
  { Selected: false, Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Email: 'barush@gmail.com' },
  { Selected: false, Name: 'Barush', Lastname: 'Mendez', Profile: 'google.com', Email: 'barush@gmail.com' },
];

const INBOX_DATA: inboxInterface[] = [
  { Name: 'Barush', Lastname: 'Mendez', Email: 'barush@gmail.com', Message: 'VSDVSDVDVSDV' },

];