import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HighlightsService } from 'src/app/services/highlights.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.scss']
})
export class HighlightsComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() info: string;
  @Input() banner: string;
  @Input() metatag: string;
  @Input() link: string;
  @Output() deleted = new EventEmitter<boolean>();
  @Output() reload = new EventEmitter<boolean>();

  hgForm: FormGroup;

  constructor(private hlService: HighlightsService, private fb: FormBuilder, private _snackBar: MatSnackBar) {
    this.hgForm = this.fb.group({
      title: [''],
      info: [''], //description
      banner: [''],
      metatag: [''],
      link: ['']
    })
  }

  ngOnInit(): void {
    this.hgForm.patchValue({
      title: this.title,
      info: this.info,
      banner: this.banner,
      metatag: this.metatag,
      link: this.link,
    })
  }


  delete() {
    this.hlService.deleteHighlight(this.id).subscribe(r => {
      this.deleted.emit(true);
      this._snackBar.open('Highlight was successfully deleted.', 'Close', {
        duration: 2000,
      });
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }

  update() {
    this.hlService.updateHighlight(this.id, this.hgForm.value).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Highlight was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this.reload.emit(true);
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }


  imageChange() {
    return this.banner;
  }
}
