<div class="main_container">
    <h1 class="container_title">ADMIN PRIVILEGES</h1>
    <mat-form-field appearance="outline">
        <mat-label>Choose an option</mat-label>
        <mat-select matNativeControl (selectionChange)="getUsers($event.value)" id="user-selection">
            <mat-option value="super">Super Admin</mat-option>
            <mat-option value="admin">Admin</mat-option>
            <mat-option value="manager">Moderator</mat-option>
            <mat-option value="basic">Basic</mat-option>
        </mat-select>
    </mat-form-field>
</div>

<div class="mat-elevation-z8 main" *ngIf="!hideTable">
    <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="Name">
            <th mat-header-cell *matHeaderCellDef id="name">Name</th>
            <td mat-cell *matCellDef="let m"> {{m.name | titlecase}} </td>
        </ng-container>


        <ng-container matColumnDef="Lastname">
            <th mat-header-cell *matHeaderCellDef id="lastname">Lastname</th>
            <td mat-cell *matCellDef="let m"> {{m.lastName | titlecase}} </td>
        </ng-container>
        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef id="email">Email</th>
            <td mat-cell *matCellDef="let m"> {{m.email }} </td>
        </ng-container>

        <ng-container matColumnDef="Privilege">
            <th mat-header-cell *matHeaderCellDef id="privilege"> Privilege</th>
            <td mat-cell *matCellDef="let m">
                <mat-form-field appearance="outline">
                    <mat-label>Change privileges</mat-label>
                    <mat-select (selectionChange)="selectAction(m._id,$event.value)" [id]="m._id">
                        <mat-option value="super">Super Admin</mat-option>
                        <mat-option value="admin">Admin</mat-option>
                        <mat-option value="manager">Moderator</mat-option>
                        <mat-option value="basic">Basic</mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <ng-container matColumnDef="Action">
            <th mat-header-cell *matHeaderCellDef id="action"></th>
            <td mat-cell *matCellDef="let m">
                <button mat-flat-button color="primary" (click)="open(content,m._id)">DELETE</button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    </table>
    <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"
        [length]="totalSize" [pageIndex]="currentPage" (page)="pageEvent = handlePage($event)" showFirstLastButtons>
    </mat-paginator>
</div>

<div class="mat-elevation-z8 main d-flex justify-content-center" *ngIf="hideTable">
    <img src=" ../../../assets/images/Search.svg" class="search-img">
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">ARE YOU SURE?</h4>
        <button type="button" class="close" aria-label="Close" (click)="this.modal.close()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p class="body_text">We are deleting this user for good.</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="this.modal.close()">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="deleteAdmin()">Ok</button>
    </div>
</ng-template>