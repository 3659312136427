import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { wordCount } from 'src/app/utils/word.validator';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-editprofile',
  templateUrl: './editprofile.component.html',
  styleUrls: ['./editprofile.component.scss']
})
export class EditprofileComponent implements OnInit {

  value;
  id: string;
  profileForm: FormGroup;
  webPattern = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+(:[0-9]+)?|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/;
  images = {
    profile: 'assets/images/profile.svg',
    poster1: 'assets/images/poster.svg',
    poster2: 'assets/images/poster.svg',
    poster3: 'assets/images/poster.svg'
  }

  constructor(private router: Router, private fb: FormBuilder, private userService: UserService) {
    this.profileForm = this.fb.group({
      contactEmail: ['', [Validators.email]],
      bio: ['', [Validators.required, wordCount(20, 300, true)]],
      preferredPronouns: [''],
      links: this.fb.group({
        imdb: ['', [Validators.pattern(this.webPattern)]],
        linkedin: ['', [Validators.pattern(this.webPattern)]],
        website: ['', [Validators.pattern(this.webPattern)]],
        customLink: ['', [Validators.pattern(this.webPattern)]],
      }),
      position: null,
      languages: null,
      verified: null,
      selfIdentification: null,
      BasedIn: null,
      racial: null,
      documentaryExperience: null,
      otherExperience: null,
      images: this.fb.group({
        profileImage: [''],
        posters: this.fb.group({
          poster1: this.fb.group({ image: [''], referenceLink: ['', [Validators.pattern(this.webPattern)]] }),
          poster2: this.fb.group({ image: [''], referenceLink: ['', [Validators.pattern(this.webPattern)]] }),
          poster3: this.fb.group({ image: [''], referenceLink: ['', [Validators.pattern(this.webPattern)]] }),
        }),
      }),
      name: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
    });
  }

  getProfile(id: string) {
    this.userService.getUser(id).subscribe(d => {
      this.getImages(d.body.images);
      this.profileForm.patchValue({
        name: d.body.user.name,
        lastName: d.body.user.lastName,
        email: d.body.user.email,
        ...d.body
      });

    })
  }

  getImages(images: any) {
    if (images == null) return;
    this.images.profile = images.profileImage !== "" ? `${images.profileImage}?${Date.now()}` : this.images.profile;
    this.images.poster1 = images.posters.poster1.image !== "" ? `${images.posters.poster1.image}?${Date.now()}` : this.images.poster1;
    this.images.poster2 = images.posters.poster2.image !== "" ? `${images.posters.poster2.image}?${Date.now()}` : this.images.poster2;
    this.images.poster3 = images.posters.poster3.image !== "" ? `${images.posters.poster3.image}?${Date.now()}` : this.images.poster3;
  }

  ngOnInit(): void {
    this.id = this.getRouteId();
    this.getProfile(this.id)
  }

  getRouteId(): string {
    return this.router.url.split('/', 4)[3];
  }

  submit() {
    const updatedProfileForm = {...this.profileForm.value};

    // Remove the verified field before sending to backend as this field is not accepted in the backend
    delete updatedProfileForm.verified;

    this.userService.updateUser(this.id, { profile: updatedProfileForm });
    setTimeout(() => {
      this.getProfile(this.id);
    }, 2000);
  }

  unverify() {
    this.userService.changeVerifiedValue(this.id, false);
    setTimeout(() => {
      this.getProfile(this.id);
    }, 2000);
  }

  uploadResume(event: any) {
    if (event.target.files.length == 1) {
      this.userService.uploadResume(this.id, { data: event.target.files[0], fileName: 'resume.pdf' });
    }
  }

  //Dropdown
  onSelect(event: any): void {
  }

  reloadImg(event: boolean) {
    this.getProfile(this.id);
  }
}
