import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, } from '@angular/forms';


@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss']
})
export class SearchbarComponent implements OnInit {
  searchForm: FormGroup;

  @Output() searchbarValue = new EventEmitter<string>();

  constructor(private fb: FormBuilder) {
    this.searchForm = this.fb.group({
      searchInput: ['']
    });
  }

  ngOnInit(): void {
    this.onChange();
  }

  onChange(): void {
    this.searchForm.valueChanges.subscribe(data => {
      this.searchbarValue.emit(data.searchInput);
    });
  }

}
