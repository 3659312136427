import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { RequestsComponent } from './components/requests/requests.component';
import { UsersComponent } from './components/users/users.component';
import { EmailComponent } from './components/email/email.component';
import { InboxComponent } from './components/inbox/inbox.component';
import { EditComponent } from './components/edit/edit.component';
import { EditprofileComponent } from './components/editprofile/editprofile.component';

import { AuthGuard } from './guards/auth.guard';
import { SecurityComponent } from './components/security/security.component';
import { AdminTypeGuard } from './guards/admin-type.guard'
import { Page404Component } from './components/page404/page404.component';


const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'signin' },
  { path: 'signin', component: LoginComponent },
  {
    path: 'admin', component: SidebarComponent, canActivate: [AuthGuard], children: [
      { path: '', redirectTo: 'requests', pathMatch: 'full' },
      { path: 'requests', component: RequestsComponent },
      {
        path: 'users', component: UsersComponent
      },
      { path: 'email', component: EmailComponent },
      { path: 'inbox', component: InboxComponent },
      { path: 'edit', component: EditComponent },
      { path: 'editprofile/:id', component: EditprofileComponent },
      { path: 'security', component: SecurityComponent, canActivate: [AdminTypeGuard] },
      { path: '**', component: Page404Component }

    ]
  },
  { path: '**', component: Page404Component }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
