import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HighlightsService } from 'src/app/services/highlights.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResourcesService } from 'src/app/services/resources.service';
import { EndorsersService } from 'src/app/services/endorsers.service';
import { PartnersService } from 'src/app/services/partners.service';


@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  highlightsForm: FormGroup;
  resourcesForm: FormGroup;
  endorsersForm: FormGroup;

  id: string = '';
  highlights: any = [];
  resources: any = [];
  endorsers: any = [];
  partners: any = [];



  constructor(private fb: FormBuilder, private hlService: HighlightsService, private _snackBar: MatSnackBar, private rService: ResourcesService, private eService: EndorsersService, private pService: PartnersService) {
    this.highlightsForm = this.fb.group({
      title: [''],
      info: [''], //description
      banner: [''], //image,
      metatag: [''],
      link: ['']
    })

    this.resourcesForm = this.fb.group({
      title: [''],
      subtitle: [''], //description
      image: [''], //image,
      description: [''],
      link: ['']
    })

    this.endorsersForm = this.fb.group({
      title: [''],
      subtitle: [''], //description
      image: [''], //image,
    })

  }

  ngOnInit(): void {
    this.fetchHighlights();
    this.fetchResource();
    this.getEndorsers();
    this.fetchPartners();
  }

  submitHighlights() {

  this.hlService.createHighlight(this.highlightsForm.value).subscribe(r => {
      this.highlightsForm.reset();
      this.fetchHighlights();
        this._snackBar.open('Highlight was successfully created.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    }) 
  }


  fetchHighlights() {
    this.hlService.getHighlights().subscribe(d => {
      this.highlights = d;
    }, e => {
      console.log(e)
    }) 
  }

  getImage(event: any, field, type: string) {
    if (type == 'endorser') {
      const file = { data: event, fileName: 'image' };
      this.endorsersForm.patchValue({
        [field]: file
      })
    } else if (type == 'resource') {
      const file = { data: event, fileName: 'image' };
      this.resourcesForm.patchValue({
        [field]: file
      })
    }else if(type=='highlight'){
      const file = { data: event, fileName: 'image' };
      this.highlightsForm.patchValue({
        [field]: file
      })
    }

  }

  createResource() {
    this.rService.createResource(this.resourcesForm.value).subscribe(r => {
      this.resourcesForm.reset();

      this.fetchResource();
      this._snackBar.open('Resource was successfully created.', 'Close', {
        duration: 2000,
      });
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }

  fetchResource() {
    this.rService.getResources().subscribe(d => {
      this.resources = d;
    }, e => {
      console.log(e)
    })


  }

  reloadResource(e: boolean) {
    if (e) {
      this.fetchResource();
    }
  }

   reloadHighlights(e: boolean) {
    if (e) {
      this.fetchHighlights();
    }
  }


  createEndorser() {
    this.eService.createEndorser(this.endorsersForm.value).subscribe(r => {
      this._snackBar.open('Resource was successfully created.', 'Close', {
        duration: 2000,
      });
      this.endorsersForm.reset();
      this.getEndorsers();
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }

  getEndorsers() {
    this.eService.getEndorsers().subscribe(d => {
      this.endorsers = d;
    })
  }

  fetchPartners() {
    this.pService.getPartners().subscribe(d => {
      this.partners = d;
    })
  }

  deletePartner(id: string) {
    this.pService.deletePartner(id).subscribe(r => {
      this._snackBar.open('Partner was successfully deleted.', 'Close', {
        duration: 2000,
      });
      this.fetchPartners();
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });

  }
}