<div class="container_title">
    <h1>{{profileForm.value.name}} {{profileForm.value.lastName}}'S PROFILE</h1>
</div>

<form [formGroup]="profileForm" (ngSubmit)="submit()">
    <div class="shadow p-3 bg-white mb-4">
        <div class="row">
            <div class="column center">
                <img [src]="images.profile" class="img-profile" alt="Profile Image">
                <app-cropper [photoType]="'profile'" (imageSaved)="reloadImg($event)" [id]="id"></app-cropper>
            </div>
            <div class="column">
                <div class=" form-group">
                    <label class="form__label" for="name">Name</label>
                    <input formControlName="name" type="text" id="name" class="form-control" />
                </div>
                <div class="form-group">
                    <label class="form__label" for="lastName">Lastname</label>
                    <input formControlName="lastName" type="text" id="lastName" class="form-control" />
                </div>
                <div class="form-group">
                    <label class="form__label" for="email">Email</label>
                    <input formControlName="contactEmail" type="email" id="contactEmail" class="form-control" />
                </div>
                <div class="form-group">
                    <label class="form__label" for="preferredPronouns">Prefered Pronouns</label>

                    <input formControlName="preferredPronouns" type="text" id="preferredPronouns"
                        class="form-control" />
                </div>
                <div class="form-group">
                    <label class="form__label" for="bio">Biography</label>
                    <textarea class="form-control msg" id="bio" rows="6" formControlName="bio"></textarea>
                </div>
                <div class="form-group row center">
                    <input #csvInput hidden="true" type="file" onclick="this.value=null" (change)="uploadResume($event)"
                        accept=".pdf" />
                    <button mat-flat-button color="primary" (click)="csvInput.click()" class="purple_button"
                        type="button">UPLOAD
                        RESUME</button>
                </div>
            </div>


        </div>
    </div>
   

    <div class="shadow p-3 bg-white mb-4">
        <div class="row">
            <div class="column ">
                <div class="form-group" formGroupName="links">
                    <label class="form__label" for="imdb">Imdb</label>
                    <input formControlName="imdb" type="text" id="imdb" class="form-control" />
                </div>
                <div class="form-group" formGroupName="links">
                    <label class="form__label" for="linkedin">Linkedin</label>
                    <input formControlName="linkedin" type="text" id="linkedin" class="form-control" />
                </div>
            </div>
            <div class="column ">
                <div class="form-group" formGroupName="links">
                    <label class="form__label" for="website">Website</label>
                    <input formControlName="website" type="text" id="website" class="form-control" />
                </div>
                <div class="form-group" formGroupName="links">
                    <label class="form__label" for="customLink">Custom</label>
                    <input formControlName="customLink" type="text" id="customLink" class="form-control" />
                </div>
            </div>
        </div>
    </div>

    <div class="shadow p-3 bg-white mt-4">
        <div class=" row distribution">
            <app-dropdown [category]="'position'" [formGroup]="profileForm" (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'otherExperience'" [formGroup]="profileForm" (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'documentaryExperience'" [formGroup]="profileForm"
                (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'BasedIn'" [formGroup]="profileForm" (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'languages'" [formGroup]="profileForm" (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'selfIdentification'" [formGroup]="profileForm"
                (selectedGroup)="onSelect($event)">
            </app-dropdown>
            <app-dropdown [category]="'racial'" [formGroup]="profileForm" (selectedGroup)="onSelect($event)">
            </app-dropdown>
        </div>

    </div>

    <div class="shadow p-3 bg-white mt-4" formGroupName="images">
        <div class="row distribution" formGroupName="posters">
            <div class="column justify-content-center align-items-center">
                <img [src]="images.poster1" alt="img-poster1" class="img-poster">
                <app-cropper [photoType]="'poster1'" (imageSaved)="reloadImg($event)" [id]="id"></app-cropper>
                <div class="form-group" formGroupName="poster1">
                    <label class="form__label" for="a">Reference Link</label>
                    <input formControlName="referenceLink" type="text" id="referenceLink" class="form-control" />
                </div>
            </div>

            <div class="column justify-content-center align-items-center">
                <img [src]="images.poster2" alt="img-poster" class="img-poster">
                <app-cropper [photoType]="'poster2'" (imageSaved)="reloadImg($event)" [id]="id"></app-cropper>
                <div class="form-group" formGroupName="poster2">
                    <label class="form__label" for="b">Reference Link</label>
                    <input formControlName="referenceLink" type="text" id="referenceLink" class="form-control" />
                </div>
            </div>

            <div class="column justify-content-center align-items-center">
                <img [src]="images.poster3" alt="img-poster" class="img-poster">
                <app-cropper [photoType]="'poster3'" (imageSaved)="reloadImg($event)" [id]="id"></app-cropper>
                <div class="form-group" formGroupName="poster3">
                    <label class="form__label" for="c">Reference Link</label>
                    <input formControlName="referenceLink" type="text" id="referenceLink" class="form-control" />
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="shadow p-3 bg-white mt-4">
        <div class="row">
            <div class="column">
                <div class="form-group" formGroupName="profileForm">
                    <label class="form__label" for="receiveJobNotifs">Receive Job Notifications</label>
                    <input formControlName="receiveJobNotifs" type="checkbox" id="receiveJobNotifs" class="ml-3" />
                </div>
            </div>
            <div class="column">
                <div class="form-group">
                    <label class="form__label" for="verified">Verified</label>
                    <input formControlName="verified" type="checkbox" id="verified" class="ml-3" />
                </div>
            </div>
            <div class="column">
                <div class="form-group">
                    <label class="form__label" for="activelyLooking">Actively Looking</label>
                    <input formControlName="activelyLooking" type="checkbox" id="activelyLooking" class="ml-3" />
                </div>
            </div>
        </div>
    </div> -->

    <div class="d-flex center">
        <button mat-flat-button color="primary" class="purple_button mb-2" type="submit">UPDATE</button>
        <button *ngIf="profileForm.value.verified" mat-flat-button color="primary" class="purple_button mb-2" type="button" (click)="unverify()">UNVERIFY</button>
    </div>
</form>