import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

import { Client, Response } from '../api.client';

export interface Res extends Response {
  _id: string,
  email: string,
  message: string,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(private apiClient: Client) { }

  getMessages(keyword: string) {
    return this.apiClient.contact2(keyword).pipe(map(data => data.body));
  }

  deleteMessages(id: string) {
    return this.apiClient.contact3(id);
  }


}
