<form [formGroup]="searchForm" class="form_container">
    <div class="form-group">
        <div class="input-group">
            <div class="input-group-text" style="border:none;">
                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M18.7088 18.0181L14.4016 13.7109C15.4386 12.3304 15.9984 10.6501 15.9965 8.92347C15.9965 4.51327 12.4084 0.925232 7.99824 0.925232C3.58804 0.925232 0 4.51327 0 8.92347C0 13.3337 3.58804 16.9217 7.99824 16.9217C9.72482 16.9236 11.4052 16.3638 12.7856 15.3268L17.0928 19.634C17.3109 19.8289 17.5953 19.933 17.8876 19.9248C18.1799 19.9166 18.458 19.7968 18.6648 19.59C18.8716 19.3832 18.9914 19.1051 18.9996 18.8128C19.0077 18.5205 18.9037 18.2361 18.7088 18.0181ZM2.28521 8.92347C2.28521 7.79354 2.62027 6.68899 3.24803 5.74948C3.87579 4.80998 4.76804 4.07773 5.81196 3.64532C6.85588 3.21292 8.00458 3.09978 9.1128 3.32022C10.221 3.54066 11.239 4.08477 12.038 4.88375C12.8369 5.68273 13.3811 6.7007 13.6015 7.80892C13.8219 8.91713 13.7088 10.0658 13.2764 11.1098C12.844 12.1537 12.1117 13.0459 11.1722 13.6737C10.2327 14.3014 9.12817 14.6365 7.99824 14.6365C6.48361 14.6347 5.03153 14.0322 3.96053 12.9612C2.88952 11.8902 2.28703 10.4381 2.28521 8.92347Z"
                        fill="#7C4DFF" />
                </svg>
            </div>
            <input type="text" class="form-control" placeholder="Search by name or keyword" id="searchInput"
                formControlName="searchInput" for="searchInput">
        </div>

    </div>
</form>