import { FormBuilder } from "@angular/forms";

const fb = new FormBuilder();
export const filters = [{
    "category": "position",
    "categoryDisplay": "Position Seeking",
    "values": ['Editor', 'Associate Editor', 'Assistant Editor', 'Consulting Editor']
}, {
    "category": "otherExperience",
    "categoryDisplay": "Other Experience",
    "values": [
        'Reality',
        'Music Video',
        'Web Videos',
        'Branded',
        'Corporate',
        'Experimental',
        'News',
        'Trailers',
        'Motion Graphics',
    ]
},
{
    "category": "documentaryExperience",
    "categoryDisplay": "Documentary Experience",
    "values": [
        'Editor (1-2 feature/series credits)',
        'Editor (3-5 feature/series credits)',
        'Editor (6+ feature/series credits)',
        'Associate Editor (1-2 credits)',
        'Associate Editor (3+ credits)',
        'Assistant Editor',
        'Editor: Shorts',
        'Looking to Break In',]
},
{
    "category": "BasedIn",
    "categoryDisplay": "Based In",
    "values": ['NY', 'LA', 'London', 'Chicago', 'San Francisco', 'Austin', 'Miami', 'Atlanta', 'Washington DC', 'Philadelphia', 'Boston', 'Seattle', 'Willing to Travel', 'Other']
},
{
    "category": "languages",
    "categoryDisplay": "Languages Spoken",
    "values": ['Arabic', 'ASL', 'Bengali', 'English', 'Farsi', 'French', 'German', 'Hindi', 'Italian', 'Japanese', 'Mandarin Chinese', 'Marathi', 'Portuguese', 'Russian', 'Spanish', 'Other']
},
{
    "category": "selfIdentification",
    "categoryDisplay": "Self Identification",
    "values": ['Female', 'Male', 'Non-Binary', 'Transgender', 'Bisexual', 'Lesbian', 'Gay', 'Queer', 'Straight']
},
{
    "category": "racial",
    "categoryDisplay": "Racial/Cultural Identification",
    "values": ['African American/Black', 'African Diaspora', 'Arab', 'Asian American', 'Asian Diaspora', 'Indigenous', 'Latinx', 'Afro Latinx', 'Middle Eastern',
        'Multiracial/multiethnic', 'Native American', 'Persian', 'South Asian', 'South Asian American', 'Other (BIPOC)']
},
{
    "category": "receiveJobNotifs",
    "categoryDisplay": "Receive Job Notifications",
    "values": [
        {
            "name": "Yes",
            "value": true,
        },
        {
            "name": "No",
            "value": false,
        },
    ],
}]

export const filtersForm = fb.group({
    BasedIn: null,
    position: null,
    languages: null,
    selfIdentification: null,
    racial: null,
    documentaryExperience: null,
    otherExperience: null,
    receiveJobNotifs: null,
})
