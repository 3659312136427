import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class PartnersService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar) { }

  createPartner(body: any) {
    return this.apiClient.partner3(body);
  }

  getPartners() {
    return this.apiClient.partner2().pipe(map(b => b.body));
  }

  deletePartner(id: string) {
    return this.apiClient.partner(id);

  }
}
