import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { map } from 'rxjs/internal/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class HighlightsService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar) { }

  getHighlights() {
    return this.apiClient.highlight3().pipe(map(b => b.body))
  }

  createHighlight(body: any) {
   return this.apiClient.highlight2(
     body.title,
     body.info,
     body.banner,
     body.metatag,
     body.link
     );
  }

  deleteHighlight(id: string) {
    return this.apiClient.highlight4(id);
 
  }
  updatePhoto(id: string, body: any) {
    return this.apiClient.image(id, body);
  }

  updateHighlight(id: string, body: any){
    return this.apiClient.highlight(id,{ highlight: { ...body } });
  }


}
