<app-searchbar (searchbarValue)="searchByKeyword($event)"></app-searchbar>
<div class="filter_container">
    <app-dropdown [category]="'position'" [formGroup]="form" (selectedGroup)="searchByFilter($event)"></app-dropdown>
    <app-dropdown [category]="'otherExperience'" [formGroup]="form" (selectedGroup)="searchByFilter($event)">
    </app-dropdown>
    <app-dropdown [category]="'documentaryExperience'" [formGroup]="form" (selectedGroup)="searchByFilter($event)">
    </app-dropdown>
    <app-dropdown [category]="'BasedIn'" [formGroup]="form" (selectedGroup)="searchByFilter($event)"></app-dropdown>
    <app-dropdown [category]="'languages'" [formGroup]="form" (selectedGroup)="searchByFilter($event)"></app-dropdown>
    <app-dropdown [category]="'selfIdentification'" [formGroup]="form" (selectedGroup)="searchByFilter($event)">
    </app-dropdown>
    <app-dropdown [category]="'racial'" [formGroup]="form" (selectedGroup)="searchByFilter($event)"></app-dropdown>
</div>
<div class="main_container">
    <h1 class="container_title">LET'S WRITE SOME EMAILS</h1>
<div class="button_container">
    <button mat-raised-button color="primary" class="container_button" (click)="changeVerifiedValue()">Show {{ verified
            ?
            'unverified' : 'verified' }} users</button>
</div>
</div>
<div class="mat-elevation-z8 main" *ngIf="!hideTable">
    <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="Selected">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox class="example-margin" color="primary" id="allCheck" (change)="checkAll($event)">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let m">
                <section class="example-section m-2">
                    <mat-checkbox class="example-margin" color="primary" [id]="m.user_id"
                        (change)="onChange($event.checked,m.user_id)" [checked]="check">
                    </mat-checkbox>
                </section>
            </td>
        </ng-container>

        <ng-container matColumnDef="Name" class="text_cell">
            <th mat-header-cell *matHeaderCellDef id="name">Name</th>
            <td mat-cell *matCellDef="let m"> {{m.name | titlecase}} </td>
        </ng-container>


        <ng-container matColumnDef="Lastname">
            <th mat-header-cell *matHeaderCellDef id="lastname">Lastname</th>
            <td mat-cell *matCellDef="let m"> {{m.lastName | titlecase}} </td>
        </ng-container>

        <ng-container matColumnDef="Email">
            <th mat-header-cell *matHeaderCellDef id="email"> Email </th>
            <td mat-cell *matCellDef="let m">
                <a href="mailto:{{m.email}}">{{m.email}}</a>
            </td>
        </ng-container>

        <ng-container matColumnDef="Profile">
            <th mat-header-cell *matHeaderCellDef id="profile"> Profile </th>
            <td mat-cell *matCellDef="let m">
                <a target="_blank"
                    href="https://bipocdoceditors.com/profile/{{m.profile}}">https://bipocdoceditors.com/profile/{{m.profile}}</a>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"
        [length]="totalSize" [pageIndex]="currentPage" (page)="pageEvent = handlePage($event)" showFirstLastButtons>
    </mat-paginator>


</div>

<div class="mat-elevation-z8 main d-flex justify-content-center" *ngIf="hideTable">
    <img src=" ../../../assets/images/Search.svg" class="search-img">
</div>
<div class="button_container">
    <button mat-flat-button color="primary" class="container_button" (click)="open(content)"
        *ngIf="selectedUsers.length>0">EMAIL</button>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="container_title" id="modal-basic-title">EMAIL TO</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="emailForm" (ngSubmit)="submit()">
            <div class="form-group">
                <input formControlName="subject" type="text" id="subject" class="form-control" placeholder="Subject" />
            </div>

            <div class="form-group">
                <textarea class="form-control msg" id="message" rows="3" formControlName="message"
                    placeholder="Message"></textarea>
            </div>
            <div class="button_container">
                <button mat-flat-button color="primary" class="container_button" type="submit">SEND</button>
            </div>
        </form>
    </div>
</ng-template>