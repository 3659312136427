<div class="shadow p-3 bg-white m-1">
    <div class="row">
        <div class="column center">
            <img [src]="image" class="coverImg">
            <app-cropper [photoType]="'endorsers'" (imgReady)="updatePhoto($event)">
            </app-cropper>
            <form [formGroup]=" endorserForm">
                <div class="form-group">
                    <input formControlName="title" type="text" id="title" class="form-control" placeholder="Endorser" />
                </div>
                <div class="form-group">
                    <input formControlName="subtitle" type="text" id="subtitle" class="form-control"
                        placeholder="Endorser Description" />
                </div>

                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="delete()">DELETE</button>
                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="update()">SAVE</button>
            </form>

        </div>
    </div>
</div>