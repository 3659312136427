import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from "@angular/material/list";
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { DomSanitizer } from "@angular/platform-browser";
import {MatChipsModule} from '@angular/material/chips'; 
import { RequestsComponent } from './components/requests/requests.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { LoginComponent } from './components/login/login.component';
import { UsersComponent } from './components/users/users.component';
import { SearchbarComponent } from './components/shared/searchbar/searchbar.component';
import { TableComponent } from './components/shared/table/table.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { EmailComponent } from './components/email/email.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InboxComponent } from './components/inbox/inbox.component';
import { EditComponent } from './components/edit/edit.component';
import { MatTabsModule } from '@angular/material/tabs';
import { HighlightsComponent } from './components/shared/highlights/highlights.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { EditprofileComponent } from './components/editprofile/editprofile.component';
import { DropdownComponent } from './components/shared/dropdown/dropdown.component';
import { CropperComponent } from './components/shared/cropper/cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ResourcesComponent } from './components/shared/resources/resources.component';
import { SecurityComponent } from './components/security/security.component';
import { EndorserComponent } from './components/shared/endorser/endorser.component';
import { Page404Component } from './components/page404/page404.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    RequestsComponent,
    UsersComponent,
    SearchbarComponent,
    TableComponent,
    EmailComponent,
    InboxComponent,
    EditComponent,
    HighlightsComponent,
    EditprofileComponent,
    DropdownComponent,
    CropperComponent,
    ResourcesComponent,
    SecurityComponent,
    EndorserComponent,
    Page404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    HttpClientModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatCheckboxModule,
    NgbModule,
    MatTabsModule,
    MatExpansionModule,
    MatSnackBarModule,
    ImageCropperModule,
    HammerModule,
    MatChipsModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('bipoc_icon', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/DBE-square.svg'));
  }
}
