<div class="shadow p-3 bg-white m-1">
    <div class="row">
        <div class="column center">
            <img [src]="imageChange()" class="coverImg">
            <app-cropper [photoType]="'highlightEdit'" (imageSaved)="imageChange()" [id]="this.id"></app-cropper>
            <form [formGroup]="hgForm">
                <div class="form-group">
                    <input formControlName="title" type="text" id="title" class="form-control" placeholder="Title" />
                </div>
                <div class="form-group">
                    <input formControlName="info" type="text" id="info" class="form-control"
                        placeholder="Information" />
                </div>
                <div class="form-group">
                    <input formControlName="metatag" type="text" id="metatag" class="form-control"
                        placeholder="Metatag" />
                </div>
                <div class="form-group">
                    <input formControlName="link" type="text" id="link" class="form-control"
                        placeholder="Link to highlight" />
                </div>

                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="delete()">DELETE</button>
                <button mat-flat-button color="primary" class="purple_button" type="button"
                    (click)="update()">SAVE</button>
            </form>

        </div>
    </div>
</div>