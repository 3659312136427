import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EndorsersService } from 'src/app/services/endorsers.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-endorser',
  templateUrl: './endorser.component.html',
  styleUrls: ['./endorser.component.scss']
})
export class EndorserComponent implements OnInit {

  endorserForm: FormGroup;

  constructor(private fb: FormBuilder, private _snackBar: MatSnackBar, private eService: EndorsersService) {
    this.endorserForm = this.fb.group({
      title: [''],
      subtitle: [''], //description
    })
  }

  @Input() id: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() image: string;
  @Output() reload = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.endorserForm.patchValue({
      title: this.title,
      subtitle: this.subtitle

    })
  }

  delete() {
    this.eService.deleteEndorser(this.id).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Endorser was successfully deleted.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });

  }

  update() {
    this.eService.updateEndorser(this.id, this.endorserForm.value).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Resource was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this.reload.emit(true);
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });

  }

  updatePhoto(event: any) {
    const file = { data: event, fileName: 'image' };
    this.eService.updatePhoto(this.id, file).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Endorsers photo was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this.reload.emit(true);
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }



}
