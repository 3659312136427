import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactService } from 'src/app/services/contact.service';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent implements OnInit {

  dataSource: any = [];
  messages: any = [];
  displayedColumns: any[] = ['Selected', 'Name', 'Email', 'Message', 'Date'];
  messageForm: FormGroup;
  selectedMsgs: any = [];
  check: boolean = false;
  hideTable: boolean;

  pageEvent: PageEvent;
  currentPage = 1;
  pageSize = 10;
  totalSize = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private contactService: ContactService, private modalService: NgbModal, private fb: FormBuilder, private userService: UserService, private _snackBar: MatSnackBar) {
    this.messageForm = this.fb.group({
      name: [''],
      email: [''],
      message: [''],
      answer: [''],
    });
  }


  ngOnInit(): void {
    this.fetchMessages();
  }

  public getShortDate(ISODate: string) {

    if(!ISODate) {
      return "Not registered";
    }

    const date = new Date(ISODate);
    const year = date.getFullYear();
    let month: any = date.getMonth() + 1;
    let dt: any = date.getDay();

    if(dt < 10) {
      dt = '0' + dt;
    }

    if(month < 10) {
      month = '0' + month;
    }

    return `${month}/${dt}/${year}`
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();

    return e;
  }

  searchByKeyword(event: string): void {
    this.fetchMessages(event);
  }

  private iterator(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.messages.slice(start, end);
    this.dataSource = part;
  }

  fetchMessages(keyword: string = ''): void {
    this.hideTable = false;
    this.contactService.getMessages(keyword).subscribe(data => {
      this.dataSource = data;
      this.messages = data;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.messages.length;
      this.currentPage = 0;
      this.iterator();
    }, error => {
      this.hideTable = true;
    });
  }


  open(content: any, _id: string): void {
    this.modalService.open(content);
    const currentMessage = this.dataSource.find(data => data._id === _id);
    this.messageForm.patchValue(currentMessage);
  }

  submit(): void {
    this.userService.emailUser(this.messageForm.value.email, { subject: 'BIPOC DOCUMENTARY EDITORS RESPONSE', message: this.messageForm.value.answer })
    this.modalService.dismissAll();
    this.messageForm.reset();
    this.modalService.dismissAll();
  }


  //Checkbox
  onChange(value: boolean, id: string): void {
    if (value) {
      this.selectedMsgs.push(id);
    } else {
      const i = this.selectedMsgs.findIndex(l => l === id);
      this.selectedMsgs.splice(i, 1);
    }
  }

  checkAll(e) {
    this.selectedMsgs = [];
    if (e.checked) {
      this.check = true;
      this.messages.forEach(element => {
        this.onChange(true, element._id);
      });
    } else {
      this.check = false;
      this.selectedMsgs = [];
    }
  }

  delete() {
    const idList = this.selectedMsgs.join();
    this.contactService.deleteMessages(idList).subscribe(d => {
      this.fetchMessages();
      this.selectedMsgs = [];
      this._snackBar.open('Messages were successfully deleted.', 'Close', {
        duration: 2000,
      });
    }, error => {
      console.log(error)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });

  }


}
