<app-searchbar (searchbarValue)="searchByKeyword($event)"></app-searchbar>
<div class="main_container">
  <h1 class="container_title">CHECK YOUR INBOX</h1>
</div>
<div class="mat-elevation-z8 main" *ngIf="!hideTable">
  <table mat-table [dataSource]="dataSource">

    <ng-container matColumnDef="Selected">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox class="example-margin" color="primary" id="allCheck" (change)="checkAll($event)">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let m">
        <section class="example-section m-2">
          <mat-checkbox class="example-margin" color="primary" [id]="m._id" (change)="onChange($event.checked,m._id)"
            [checked]="check">
          </mat-checkbox>
        </section>
      </td>
    </ng-container>

    <ng-container matColumnDef="Name">
      <th mat-header-cell *matHeaderCellDef id="name">Name</th>
      <td mat-cell *matCellDef="let m"> {{m.name | titlecase}} </td>
    </ng-container>

    <ng-container matColumnDef="Email">
      <th mat-header-cell *matHeaderCellDef id="email"> Email </th>
      <td mat-cell *matCellDef="let m">
        <a href="mailto:{{m.email}}">{{m.email }}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="Message">
      <th mat-header-cell *matHeaderCellDef id="message">Message</th>
      <td mat-cell *matCellDef="let m"> <a (click)="open(content, m._id)">View message</a></td>
    </ng-container>

    <ng-container matColumnDef="Date">
      <th mat-header-cell *matHeaderCellDef id="date">Date</th>
      <td mat-cell *matCellDef="let m"> {{ getShortDate(m.date) }}</td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


  </table>

  <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15]" [showFirstLastButtons]="true"
    [length]="totalSize" [pageIndex]="currentPage" (page)="pageEvent = handlePage($event)" showFirstLastButtons>
  </mat-paginator>


</div>
<div class="mat-elevation-z8 main d-flex justify-content-center" *ngIf="hideTable">
  <img src=" ../../../assets/images/Search.svg" class="search-img">
</div>
<div class="button_container">
  <button mat-flat-button color="primary" class="container_button" (click)="delete()" type="button"
    *ngIf="selectedMsgs.length>0">DELETE</button>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="container_title" id="modal-basic-title">NEW MESSAGE</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="messageForm" (ngSubmit)="submit()">

      <div class="form-group">
        <input formControlName="name" type="text" id="modalname" class="form-control" placeholder="Name" />
      </div>
      <div class="form-group">
        <input formControlName="email" type="email" id="inputEmail" class="form-control" placeholder="Email" />
      </div>
      <div class="form-group">
        <textarea class="form-control msg" id="modalmessage" rows="3" formControlName="message"
          placeholder="Message"></textarea>
      </div>


      <div class="modal-header">
        <h4 class="container_title" id="title">YOUR ANSWER</h4>
      </div>
      <div class="form-group">
        <textarea class="form-control msg" id="answer" rows="3" formControlName="answer"
          placeholder="Your answer"></textarea>
      </div>
      <div class="button_container">
        <button mat-flat-button color="primary" class="container_button" type="submit">SEND</button>
      </div>
    </form>
  </div>
</ng-template>
