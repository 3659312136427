import { Component, OnInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl, FormGroup } from '@angular/forms';
import { filtersForm } from 'src/app/utils/filters';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent implements OnInit {

  //Dropdowns
  form: FormGroup;

  // Table
  dataSource: any = [];
  users: any = [];
  displayedColumns: any[] = ['Name', 'Lastname', 'Profile', 'documentaryExperience','Action'];
  hideTable: boolean;

  // Paginator
  pageEvent: PageEvent;
  currentPage = 1;
  pageSize = 5;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild('content') private content;
  // @ViewChild('content', { static: false }) private content;

  verified = false;

  denyUserId: string = null;

  userId: string = '';

  constructor(private userService: UserService, private modalService: NgbModal) {
    this.form = filtersForm;
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  fetchUsers(): void {
    this.hideTable = false;
    this.userService.getRequests(String(this.verified).toString()).subscribe(d => {
      this.dataSource = d;
      this.users = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.users.length;
      this.currentPage = 0;
      this.iterator();
    }, e => {
      this.hideTable = true;
    });
  }

  //Button
  changeVerifiedValue(): void {
    this.verified = !this.verified;
    this.fetchUsers();
  }

  // Paginator
  private iterator(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.users.slice(start, end);
    this.dataSource = part;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();

    return e;
  }

  cancelDenyUser() {
    this.denyUserId = null;
    this.modalService.dismissAll();
  }

  async denyUser() {
    if (this.denyUserId === null) return; 

    await this.userService.deleteUsersV2(this.users.find( user => user._id === this.denyUserId ).user_id);
    
    this.cancelDenyUser();
    this.fetchUsers();
  }

  //Dropdown
  async selectAction(e) {
    if (e.value === 'accept') {
      this.userService.changeVerifiedValue(e.source._id, true);
      this.fetchUsers();
    } else if (e.value === 'deny') {
      // Add a confirmation before deleting the user
      this.denyUserId = e.source._id;
      this.modalService.open(this.content);
    }
  }


}
