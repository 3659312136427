import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { map } from 'rxjs/internal/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar) { }

  createResource(body: any) {
    return this.apiClient.resources(body.title, body.subtitle, body.description, body.image, body.link)
  }


  getResources() {
    return this.apiClient.resources2().pipe(map(b => b.body));
  }

  deleteResource(id: string) {
    return this.apiClient.resources3(id);
  }

  updateResource(id: string, body: any) {
    return this.apiClient.resources4(id, { resource: body });
  }

  updatePhoto(id: string, file: any) {
    return this.apiClient.photo2(id, file);
  }

}