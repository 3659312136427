<div class="flex-column-start">
    <h1>CHOOSE WISELY</h1>
</div>

<mat-tab-group>

    <mat-tab label="Highlights">
        <form [formGroup]="highlightsForm" (ngSubmit)="submitHighlights()">
            <div class="shadow p-3 bg-white mt-4">
                <div class="row">
                    <div class="column center" >
                        <div class="form-group">
                            <input formControlName="title" type="text" id="title" class="form-control"
                                placeholder="Title" />
                        </div>
                        <div class="form-group">
                            <input formControlName="info" type="text" id="info" class="form-control"
                                placeholder="Highlight Description" />
                        </div>
                        <div class="form-group">
                            <input formControlName="link" type="text" id="link" class="form-control"
                                placeholder="Link to documentary highlight" />
                        </div>
                        <div class="form-group">
                            <input formControlName="metatag" type="text" id="metatag" class="form-control"
                                placeholder="Photo Description" />
                        </div>
                    <div class="form-group">
                        <app-cropper [photoType]="'highlight'" (imgReady)="getImage($event,'banner','highlight')">
                        </app-cropper>
                    </div>
                    <div class="form-group">
                        <button mat-flat-button color="primary" class="purple_button" type="submit">ADD
                            HIGHLIGHT</button>
                    </div>

                    </div>

                </div>




            </div>


        </form>

        <div class="flex-column-start mt-4">
            <h1>CURRENT HIGHLIGHTS</h1>
            <div class="row">
                <div class="column" *ngFor="let h of highlights">
                    <app-highlights [title]="h.title" [info]="h.info" [banner]="h.banner" [metatag]="h.metatag"
                        [link]="h.link" [id]="h._id" (deleted)="reloadHighlights($event)">
                    </app-highlights>
                </div>

            </div>


        </div>

    </mat-tab>
    <mat-tab label="Resources">
        <form [formGroup]="resourcesForm">
            <div class="shadow p-3 bg-white mt-4">
                <div class="row">
                    <div class="column center">
                        <div class="form-group">
                            <input formControlName="title" type="text" id="title" class="form-control"
                                placeholder="Title" />
                        </div>
                        <div class="form-group">
                            <input formControlName="subtitle" type="text" id="subtitle" class="form-control"
                                placeholder="Resource Description" />
                        </div>
                        <div class="form-group">
                            <input formControlName="link" type="text" id="link" class="form-control"
                                placeholder="Link/email to resource" />
                        </div>
                        <div class="form-group">
                            <input formControlName="description" type="text" id="description" class="form-control"
                                placeholder="Photo Description" />
                        </div>
                        <div class="form-group">
                            <app-cropper [photoType]="'resources'" (imgReady)="getImage($event,'image','resource')">
                            </app-cropper>
                        </div>
                        <div class="form-group">
                            <button mat-flat-button color="primary" class="purple_button" type="button"
                                (click)="createResource()">ADD RESOURCE</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div class="flex-column-start mt-4">
            <h1>CURRENT RESOURCES</h1>
            <div class="row">
                <div class="column" *ngFor="let r of resources">
                    <app-resources [id]="r._id" [title]="r.title" [subtitle]="r.subtitle" [image]="r.image"
                        [description]="r.description" [link]="r.link" (reload)="reloadResource($event)">
                    </app-resources>
                </div>


            </div>


        </div>

    </mat-tab>
    <mat-tab label="Endorsers">
        <form [formGroup]="endorsersForm">
            <div class="shadow p-3 bg-white mt-4">
                <div class="row">
                    <div class="column center">
                        <div class="form-group">
                            <input formControlName="title" type="text" id="title" class="form-control"
                                placeholder="Title" />
                        </div>
                        <div class="form-group">
                            <input formControlName="subtitle" type="text" id="subtitle" class="form-control"
                                placeholder="Resource Description" />
                        </div>
                        <div class="form-group">
                            <app-cropper [photoType]="'endorsers'" (imgReady)="getImage($event,'image','endorser')">
                            </app-cropper>
                        </div>
                        <div class="form-group"><button mat-flat-button color="primary" class="purple_button"
                                type="button" (click)="createEndorser()">ADD
                                ENDORSER</button></div>

                    </div>
                </div>
            </div>
        </form>

        <div class="flex-column-start mt-4">
            <h1>CURRENT ENDORSERS</h1>
            <div class="row">
                <div class="column" *ngFor="let e of endorsers">
                    <app-endorser [id]="e._id" [title]="e.title" [subtitle]="e.subtitle" [image]="e.image"
                        (reload)="this.getEndorsers()">
                    </app-endorser>
                </div>


            </div>


        </div>
    </mat-tab>
    <mat-tab label="Partners">
        <div class="shadow p-3 bg-white mt-4">
            <div class="row center">
                <app-cropper [photoType]="'partner'" (imageSaved)="fetchPartners()">
                </app-cropper>
            </div>
        </div>


        <div class="flex-column-start mt-4">
            <h1>CURRENT PARTNERS</h1>
            <div class="row">
                <div class="column center" *ngFor="let p of partners;">
                    <div class="shadow p-3 bg-white m-2">
                        <img [src]="p.image" class="partner_image">
                        <div class="mb-3 d-flex justify-content-center">
                            <button mat-flat-button color="primary" class="purple_button " type="button"
                                (click)="deletePartner(p._id)">DELETE
                            </button>
                        </div>

                    </div>

                </div>


            </div>


        </div>
    </mat-tab>
</mat-tab-group>