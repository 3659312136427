import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';



interface Credentials {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar, private router: Router) { }

  login(credentials: Credentials): void {
    this.apiClient.signin(credentials).subscribe(data => {

      const { token, _id } = data.body;

      const decodedToken: any = jwt_decode(token);


      if (decodedToken.role === 'basic') {
        this._snackBar.open('Basic users are not allowed to access here.', 'Close', {
          duration: 2000,
        });
        return;
      }


      localStorage.setItem("TOKEN", token);
      localStorage.setItem("ID", _id);
      this.router.navigate(['/admin']);
    }, error => {
      this._snackBar.open('Something went wrong! Please verify your credentials', 'Close', {
        duration: 2000,
      });
    });
  }

  logout(): void {
    try {
      localStorage.removeItem('TOKEN');
      localStorage.removeItem('ID');
      this.router.navigate(['/signin']);

    } catch (error) {
      console.log(error)

    }
    /*     const jwtToken = localStorage.getItem("TOKEN");
        console.log(jwtToken)
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ID');
        this.router.navigate(['/signin']); */
  }


  public isLogged(): boolean {
    const token = this.getToken();
    if (!token) return false;
    const decodedToken: any = jwt_decode(token);
    if (!AuthService.isTokenExpired(decodedToken)) {
      return true;
    }
  }

  private static isTokenExpired(decodedToken: any): boolean {
    return (Math.floor((new Date).getTime() / 1000)) >= decodedToken.exp;
  }

  getToken(): string {
    return localStorage.getItem('TOKEN');
  }

  isSuperAdmin(): boolean {
    const token = this.getToken();
    const decodedToken: any = jwt_decode(token);
    if (decodedToken.token === 'super') {
      return true;
    }
    return false;
  }

  getRecoverEmail(body: any) {
    return this.apiClient.recover(body.email);
  }





}
