import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { filters } from "../../../utils/filters";
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() category: string;
  @Input() formGroup: FormGroup;
  @Output() selectedGroup: EventEmitter<any> = new EventEmitter<any>();

  title: string;
  values: any = [];
  fc = new FormControl();

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.getCategory();

  }

  getCategory() {
    filters.filter(f => {
      if (String(f.category) === this.category) {
        this.title = f.categoryDisplay;
        this.values = f.values;
      }
    })
  }

  onChange(e) {
    this.selectedGroup.emit(this.formGroup.value);
  }

}


