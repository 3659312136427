import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { map } from 'rxjs/internal/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar) {
  }

  getUsers(keyword: string) {
    return this.apiClient.search({ keyword }).pipe(map(d => d.body));
  }

  adminSearch(keyword: string, query: any, verified: boolean) {
    return this.apiClient.admin({ keyword, query: { verified, ...query } }).pipe(map( d => d.body ));
  }

  getSearch(keyword: string, obj: any) {
    return this.apiClient.search({ keyword, query: obj }).pipe(map(d => d.body));
  }

  deleteUsersV2(ids: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.apiClient.user3(ids).subscribe(data => {
        this._snackBar.open('Users were successfully deleted.', 'Close', {
          duration: 2000,
        });
        resolve();
      }, error => {
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
        reject(error);
      });
    });
  }

  deleteUsers(ids: string): void {
    this.apiClient.user3(ids).subscribe(data => {
      this._snackBar.open('Users were successfully deleted.', 'Close', {
        duration: 2000,
      });
    }, error => {
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }

  getRequests(status: string) {
    return this.apiClient.profile2(status).pipe(map(d => d.body));
  }

  changeVerifiedValue(_id: string, status: boolean) {
    this.apiClient.status(_id, status).subscribe(data => {
    }, error => {
    });
  }



  getUser(id: string) {
    return this.apiClient.profile3(id);
  }

  updateUser(id: string, body: any) {
    this.apiClient.profile4(id, body).subscribe(r => {
      this._snackBar.open('User was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    }
    )
  }

  uploadResume(id: string, body: any) {
    this.apiClient.resume(id, body).subscribe(r => {
      this._snackBar.open('Resume was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    })
  }


  emailUser(ids: string, body: any) {
    this.apiClient.emails(ids, { email: body }).subscribe(r => {
      this._snackBar.open('Sent.', 'Close', {
        duration: 2000,
      });
    }, e => {
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    })
  }

  uploadPhoto(id: string, type: any, file: any) {
    return this.apiClient.photo(id, type, file);
  }

  getUsersRole(role: any) {
    return this.apiClient.role(role).pipe(map(d => d.body));
  }

  changeRole(id: string, role: any) {
    this.apiClient.changerole(id, role).subscribe(r => {
      this._snackBar.open('Role was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    })
  }
}

