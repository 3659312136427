import { Component, Input, OnInit, TemplateRef, ViewChild, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { HighlightsService } from 'src/app/services/highlights.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PartnersService } from 'src/app/services/partners.service';
@Component({
  selector: 'app-cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CropperComponent implements OnInit {

  croppedImageBlob: Blob;

  constructor(private modalService: NgbModal, private userService: UserService, private _snackBar: MatSnackBar, private highlightService: HighlightsService, private rService: ResourcesService, private pService: PartnersService) { }

  @Input() photoType: string;
  @Input() id: string;
  @Output() imageSaved = new EventEmitter<boolean>();
  @Output() imgReady = new EventEmitter<any>();
  selectedRatio: number = 1 / 1;

  @ViewChild('modalImg')
  public modalImgRef: TemplateRef<any>;

  ngOnInit(): void {
    if (this.photoType != 'profile') this.selectedRatio = 11 / 16;
    if (this.photoType == 'resources' || this.photoType == 'resourcesEdit' || this.photoType == 'endorsers' || this.photoType == 'partner') this.selectedRatio = 4 / 3;

  }

  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn: any;
  fileName: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.fileName = event.target.files[0].name;
    this.modalService.open(this.modalImgRef);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64, this.fileName
    )
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  uploadImage() {
    if (this.photoType === 'highlight') {
      this.imgReady.emit(this.fileToReturn);
      this.modalService.dismissAll();
    } else if (this.photoType === 'highlightEdit') {
      this.highlightService.updatePhoto(this.id,{data:this.fileToReturn,fileName:"image-upload"}).subscribe(r=>{
        this.imageSaved.emit(true);
        this._snackBar.open('Resource photo was successfully updated.', 'Close', {
          duration: 2000,
        });
      },e=>{
           this.imageSaved.emit(true);
        console.log(e)
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
      })
      this.modalService.dismissAll();
    } else if (this.photoType === 'resources') {
      this.imgReady.emit(this.fileToReturn);
      this.modalService.dismissAll();
    } else if (this.photoType == 'resourcesEdit') {
      this.rService.updatePhoto(this.id, { data: this.fileToReturn, fileName: "image-upload" }).subscribe(r => {
        this.imageSaved.emit(true);
        this._snackBar.open('Resource photo was successfully updated.', 'Close', {
          duration: 2000,
        });
      }, e => {
        this.imageSaved.emit(true);
        console.log(e)
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
      });
      this.modalService.dismissAll();
    } else if (this.photoType === 'endorsers') {
      this.imgReady.emit(this.fileToReturn);
      this.modalService.dismissAll();
    } else if (this.photoType === 'partner') {
      this.pService.createPartner({ data: this.fileToReturn, fileName: "image-upload" }).subscribe(r => {
        this.imageSaved.emit(true);
        this._snackBar.open(`Partner's photo was successfully updated.`, 'Close', {
          duration: 2000,
        });
      }, e => {
        console.log(e)
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
      })
      this.modalService.dismissAll();
    }
    else {
      this.userService.uploadPhoto(this.id, this.photoType, { data: this.fileToReturn, fileName: "image-upload" }).subscribe(r => {

        this._snackBar.open('Photo was successfully updated.', 'Close', {
          duration: 2000,
        });
        this.imageSaved.emit(true);
      }, e => {
        console.log(e);
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
      });
      this.modalService.dismissAll();
    }
  }


  imageLoaded() {
  }
  cropperReady() {
  }
  loadImageFailed() {

    this._snackBar.open('Image load failed.', 'Close', {
      duration: 2000,
    });
    
  }


}
