import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  forgotPassword: FormGroup;


  constructor(private fb: FormBuilder, private authService: AuthService, private modalService: NgbModal, private _snackBar: MatSnackBar, private router: Router) {
    this.signinForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    })

    this.forgotPassword = this.fb.group({
      email: ['', [Validators.email, Validators.required]]
    });
  }



  ngOnInit(): void {
    if (this.authService.isLogged) {
      this.router.navigate(['/admin']);
    }

  }

  submit() {
    if (this.signinForm.invalid) {
      return;
    }
    this.authService.login(this.signinForm.value);
  }


  open(content) {
    this.modalService.open(content);
  }

  recoverPassword() {
    if (!this.forgotPassword.invalid) {
      console.log(this.forgotPassword.value)
      this.authService.getRecoverEmail(this.forgotPassword.value).subscribe(r => {
        this._snackBar.open('Email was sent.', 'Close', {
          duration: 2000,
        });
        this.modalService.dismissAll()
        this.forgotPassword.reset()
      }, e => {
        console.log(e)
        this._snackBar.open('There was an error, please try again later.', 'Close', {
          duration: 2000,
        });
      }
      )
    }
    return;

  }





}
