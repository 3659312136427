import { Injectable } from '@angular/core';
import { Client } from '../api.client';
import { map } from 'rxjs/internal/operators/map';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class EndorsersService {

  constructor(private apiClient: Client, private _snackBar: MatSnackBar) { }

  createEndorser(body: any) {
    return this.apiClient.endorser2(body.image, body.title, body.subtitle);
  }

  getEndorsers() {
    return this.apiClient.endorser().pipe(map(b => b.body));
  }

  deleteEndorser(id: string) {
    return this.apiClient.endorser4(id);
  }

  updateEndorser(id: string, body: any) {
    return this.apiClient.endorser3(id, { endorser: body });
  }

  updatePhoto(id: string, body: any) {
    return this.apiClient.photo3(id, body)
  }
}
