import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/services/user.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { filtersForm } from 'src/app/utils/filters';
import { DomSanitizer } from '@angular/platform-browser';

import * as json2csv from "json2csv";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  //Dropdowns
  form: FormGroup;

  dataSource: any = [];
  users: any = [];
  displayedColumns: any[] = ['Selected', 'Name', 'Lastname', 'Profile', 'Email'];

  pageEvent: PageEvent;
  currentPage = 1;
  pageSize = 5;
  totalSize = 0;

  selectedUsers: any = [];
  currentUse: any;

  check: boolean = false;
  hideTable: boolean;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private modalService: NgbModal, private userService: UserService, private router: Router, private domSanitizer: DomSanitizer) {
    this.form = filtersForm;
  }

  ngOnInit(): void {
    this.fetchUsers();
  }

  generateCSV() {
    // https://stackoverflow.com/a/52400745

    // const fields = ['_id', 'user.name', 'user.lastName'];

    const fields = [
      {
        label: 'First Name',
        value: 'user.name',
      },
      {
        label: 'Last Name',
        value: 'user.lastName',
      },
      {
        label: 'Email',
        value: 'contactEmail',
      },
    ];

    const opts = { fields, output: "export.csv" };

    let csv;

    try {
      const parser = new json2csv.Parser(opts);
      csv = parser.parse(this.users.filter(user => this.selectedUsers.includes(user.user._id)));
      console.log(csv);
    } catch (err) {
      console.error(err);
    }

    return this.domSanitizer.bypassSecurityTrustUrl('data:text/csv,' + encodeURIComponent(csv));
  }

  fetchUsers(keyword: string = ''): void {
    this.hideTable = false;
    this.userService.getUsers(keyword).subscribe(d => {
      this.dataSource = d;
      this.users = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.users.length;
      this.currentPage = 0;
      this.iterator();
    }, e => {
      this.hideTable = true;
    });
  }

  open(content) {
    this.modalService.open(content);
  }

  edit(content) {
    this.router.navigate(['/admin/editprofile/', content[0]]);
  }

  deleteUser(): void {
    const idList = this.selectedUsers.join();
    this.userService.deleteUsers(idList);
    this.modalService.dismissAll();
    this.fetchUsers();
  }

  searchByKeyword(event: string): void {
    this.fetchUsers(event);
  }

  searchByFilter(event: any): void {
    let updatedEvent = event;

    if (
      updatedEvent.receiveJobNotifs === null || 
      (
        updatedEvent.receiveJobNotifs.constructor === Array &&
        [0, 2].includes(updatedEvent.receiveJobNotifs.length)
      )) {
      delete updatedEvent["receiveJobNotifs"];
    } else {
      updatedEvent["receiveJobNotifs"] = updatedEvent["receiveJobNotifs"][0];
    }

    this.hideTable = false;
    this.userService.getSearch('', updatedEvent).subscribe(d => {
      this.dataSource = d;
      this.users = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.users.length;
      this.iterator();
    }, e => {
      this.hideTable = true;
    })
  }
  //Paginator
  private iterator(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.users.slice(start, end);
    this.dataSource = part;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();

    return e;
  }

  //Checkbox
  onChange(value: boolean, id: string): void {
    if (value) {
      this.selectedUsers.push(id);
    } else {
      const i = this.selectedUsers.findIndex(l => l === id);
      this.selectedUsers.splice(i, 1);
    }
  }

  checkAll(e) {
    if (e.checked) {
      this.check = true;
      this.users.forEach(element => {
        this.onChange(true, element.user._id);
      });
    } else {
      this.check = false;
      this.selectedUsers = [];
    }
  }

}
