import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent implements OnInit {

  // Table
  dataSource: any = [];
  admins: any = [];
  displayedColumns: any[] = ['Name', 'Lastname', 'Email', 'Privilege', 'Action'];
  verified = false;
  currentId: string = null;
  hideTable: boolean;

  // Paginator
  pageEvent: PageEvent;
  currentPage = 1;
  pageSize = 5;
  totalSize = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private userService: UserService, private authService: AuthService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.getUsers('basic')
  }


  open(content, _id: string) {
    this.currentId = _id;
    this.modalService.open(content).result.then((data) => {
      this.currentId = null;
    }, (reason) => {
      this.currentId = null;
    });

  }

  // Paginator
  private iterator(): void {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.admins.slice(start, end);
    this.dataSource = part;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();

    return e;
  }

  selectAction(id: string, role: any) {
    this.userService.changeRole(id, role);
    this.getUsers(role)
  }

  getUsers(role: string) {
    this.hideTable = false;
    this.userService.getUsersRole(role).subscribe(d => {
      this.dataSource = d;
      this.admins = d;
      this.dataSource.paginator = this.paginator;
      this.totalSize = this.admins.length;
      this.currentPage = 0;
      this.iterator();
    }, e => {
      this.hideTable = true;
    })

  }

  deleteAdmin() {
    if (!this.currentId) return;
    this.userService.deleteUsers(this.currentId);
    this.getUsers('basic');
  }




}
