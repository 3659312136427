import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ResourcesService } from 'src/app/services/resources.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit {

  resourcesForm: FormGroup;

  constructor(private rService: ResourcesService, private fb: FormBuilder, private _snackBar: MatSnackBar) {
    this.resourcesForm = this.fb.group({
      title: [''],
      subtitle: [''], //description
      description: [''],
      link: ['']
    })
  }

  @Input() id: string;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() image: string;
  @Input() description: string;
  @Input() link: string;
  @Output() reload = new EventEmitter<boolean>();


  ngOnInit(): void {
    this.resourcesForm.patchValue({
      title: this.title,
      subtitle: this.subtitle,
      description: this.description,
      link: this.link
    })
  }

  delete() {
    this.rService.deleteResource(this.id).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Resource was successfully deleted.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this.reload.emit(true);
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }

  update() {
    this.rService.updateResource(this.id, this.resourcesForm.value).subscribe(r => {
      this.reload.emit(true);
      this._snackBar.open('Resource was successfully updated.', 'Close', {
        duration: 2000,
      });
    }, e => {
      this.reload.emit(true);
      console.log(e)
      this._snackBar.open('There was an error, please try again later.', 'Close', {
        duration: 2000,
      });
    });
  }



  refresh(e: any) {
    this.reload.emit(true)
    this.imageChange()
  }


  imageChange() {
    return this.image;
  }


}
