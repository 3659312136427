import { AbstractControl, ValidatorFn } from '@angular/forms';

export function wordCount(min: number, max: number, required: boolean): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        const words = control.value ? control.value.split(/\s+/) : 0;
        if ((words.length >= min && words.length <= max) && required) {
            return null;
        } else if (words.length == null && !required) {
            return null;
        }
        return { 'wordRange': true };
    };
}