<mat-sidenav-container class="main" autosize>
    <mat-sidenav #sidenav class="sidenav" mode="side" opened="true">

        <mat-nav-list>
            <mat-list-item>
                <button mat-icon-button (click)="isExpanded = !isExpanded">
                    <mat-icon mat-list-icon svgIcon="bipoc_icon" *ngIf="!isExpanded"></mat-icon>
                    <mat-icon mat-list-icon svgIcon="bipoc_icon" *ngIf="isExpanded"></mat-icon>
                </button>
            </mat-list-item>
            <mat-list-item routerLink="./requests">
                <mat-icon mat-list-icon>verified_user</mat-icon>
                <p matLine *ngIf="isExpanded">REQUESTS</p>
            </mat-list-item>
            <mat-list-item routerLink="./users">
                <mat-icon mat-list-icon>supervisor_account</mat-icon>
                <p matLine *ngIf="isExpanded">USERS</p>
            </mat-list-item>
            <mat-list-item routerLink="./email">
                <mat-icon mat-list-icon>email</mat-icon>
                <p matLine *ngIf="isExpanded">EMAIL USERS</p>
            </mat-list-item>
            <mat-list-item routerLink="./inbox">
                <mat-icon mat-list-icon>chat</mat-icon>
                <p matLine *ngIf="isExpanded">DMS</p>
            </mat-list-item>
            <mat-list-item routerLink="./edit">
                <mat-icon mat-list-icon>mode_edit</mat-icon>
                <p matLine *ngIf="isExpanded">EDIT WEBSITE</p>
            </mat-list-item>
            <mat-list-item routerLink="./security" *ngIf="superAdmin()">
                <mat-icon mat-list-icon>security</mat-icon>
                <p matLine *ngIf="isExpanded">SECURITY</p>
            </mat-list-item>
            <mat-list-item (click)="logout()">
                <mat-icon mat-list-icon>meeting_room</mat-icon>
                <p matLine *ngIf="isExpanded">LOGOUT</p>
            </mat-list-item>

        </mat-nav-list>
    </mat-sidenav>

    <div class="sidenav-content">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>